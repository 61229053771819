import React, { Fragment } from 'react';

/* Material UI */
import { Button, Grid, Typography } from '@mui/material';
import GavelIcon from '@mui/icons-material/Gavel';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

/* Extra */
import { useHistory } from 'react-router-dom';

/* Project */
import buildingImg from 'utils/images/building.png';
import keyImg from 'utils/images/key.png';
import treeImg from 'utils/images/tree.png';
import carImg from 'utils/images/car.png';
import { basicUrlPagination, getAuctionTypeName, getItemInStorage } from 'utils/functions';
import { defaultQueries, externalLink } from 'utils/helpers/helper';
import Paths from 'config/paths';

const liveGridStyle = { marginLeft: 'auto', marginBottom: 5 };

function MobileLayout(props) {
  const { classes, propertyLive, furnitureLive, globals, user } = props;

  const history = useHistory();

  const propertyLiveCode = propertyLive?.AuctionType?.code;
  const propertyName = getAuctionTypeName(propertyLive?.AuctionType?.code, 2).live;
  const furtinureName = getAuctionTypeName(furnitureLive?.AuctionType?.code, 1).live;

  const link = globals.rolExternalLink;

  function gotoExternal(areaID) {
    // window.open(`${globals.rolExternalLink}/auctionList?areaId=${id}`, '_blank', 'noopener');
    window.open(
      `${externalLink(getItemInStorage('user'), link)}&param2=${areaID}`,
      '_blank',
      'noopener',
    );
  }

  const redirectSale = () => {
    if (user === undefined || user.roleId === null || Object.keys(user).length === 0) {
      history.push('/direct_sale');
    } else {
      history.push(`/lots?areaId=1&lotType=sale${basicUrlPagination}`);
    }
  };

  const redirectFinishedAuctions = () => {
    history.push(`${Paths.FINISHED_AUCTIONS.replace(':areaID', '1')}?&page=1&limit=10`);
  };

  const propertyGrid = (
    <Grid item xs={12} className={classes.detailsHeader}>
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.detailsTitle}>PROPIEDADES</Typography>
        </Grid>
        <Grid item xs={6} className={classes.alignRight} style={liveGridStyle}>
          {propertyLive && propertyLiveCode !== 'BID' && (
            <Button className={classes.onLiveButton} onClick={() => gotoExternal(2)}>
              Ir {propertyName}
              <GavelIcon style={{ marginLeft: '6px' }} className={classes.gavelIcon} />
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item container sm={12} xs={12} alignItems="center">
          <Button
            className={classes.shortcutButton}
            onClick={() => history.push(`/lots?areaId=2&auctionCategoryId=5${basicUrlPagination}`)}>
            <Grid item container alignItems="center">
              <Grid item sm={2} xs={2} container>
                <img src={buildingImg} className={classes.areaImage} alt="Urban properties icon" />
              </Grid>
              <Grid item sm={8} xs={8} container justifyContent="flex-start">
                URBANAS
              </Grid>
              <Grid item sm={2} xs={2}>
                <ArrowForwardIcon />
              </Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item container sm={12} xs={12} alignItems="center">
          <Button
            className={classes.shortcutButton}
            onClick={() => history.push(`/lots?areaId=2&auctionCategoryId=4${basicUrlPagination}`)}>
            <Grid item container alignItems="center">
              <Grid item sm={2} xs={2} container>
                <img src={treeImg} className={classes.areaImage} alt="Fields icon" />
              </Grid>
              <Grid item sm={8} xs={8} container justifyContent="flex-start">
                CAMPOS
              </Grid>
              <Grid item sm={2} xs={2}>
                <ArrowForwardIcon />
              </Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item container sm={12} xs={12} alignItems="center">
          <Button
            className={classes.shortcutButton}
            onClick={() => (window.location.href = 'https://arriendos.tattersallap.cl')}>
            <Grid item container alignItems="center">
              <Grid item sm={2} xs={2} container justifyContent="flex-start">
                <img src={keyImg} className={classes.areaImage} alt="Rents icon" />
              </Grid>
              <Grid item sm={8} xs={8} container justifyContent="flex-start">
                ARRIENDOS
              </Grid>
              <Grid item sm={2} xs={2}>
                <ArrowForwardIcon />
              </Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>
      <Grid container className={classes.buttonContainer}>
        <Grid item xs={6} className={classes.detailsLeftButton}>
          <Button
            className={classes.bigLightBlueButton}
            onClick={() => history.push(`/auctions${defaultQueries.properties.auctions}`)}>
            PRÓXIMOS REMATES Y LICITACIONES
          </Button>
        </Grid>
        <Grid item xs={6} className={classes.detailsRightButton}>
          <Button
            className={classes.bigLightBlueButton}
            onClick={() => history.push(`/lots?areaId=2&lotType=sale${basicUrlPagination}`)}>
            {' '}
            VENTAS DIRECTAS
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  const furnitureGrid = (
    <Grid item xs={12} className={classes.detailsHeader}>
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.detailsTitle}>BIENES</Typography>
        </Grid>
        <Grid item xs={6} className={classes.alignRight} style={liveGridStyle}>
          {furnitureLive && (
            <Button className={classes.onLiveButton} onClick={() => gotoExternal(1)}>
              Ir {furtinureName}
              <GavelIcon style={{ marginLeft: '6px' }} className={classes.gavelIcon} />
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ marginTop: '1.5rem', textAlign: 'center', height: '70%' }}>
        <Grid item container spacing={3}>
          <Grid item container sm={12} xs={12} alignItems="center">
            <Button
              className={classes.shortcutButton}
              onClick={() => history.push(`/auctions${defaultQueries.fortinures.auctions}`)}>
              <Grid item container alignItems="center">
                <Grid item sm={2} xs={2} container justifyContent="flex-start">
                  <GavelIcon className={classes.areaImage} style={{ width: '2.6vh' }} />
                </Grid>
                <Grid item sm={8} xs={8} container justifyContent="flex-start">
                  PRÓXIMOS REMATES / LICITACIONES
                </Grid>
                <Grid item sm={2} xs={2}>
                  <ArrowForwardIcon />
                </Grid>
              </Grid>
            </Button>
          </Grid>
        </Grid>
        <Grid item container spacing={3}>
          <Grid item container sm={12} xs={12} alignItems="center">
            <Button className={classes.shortcutButton} onClick={redirectSale}>
              <Grid item container alignItems="center">
                <Grid item sm={2} xs={2} container justifyContent="flex-start">
                  <img src={carImg} className={classes.areaImage} alt="Vehicles icon" />
                </Grid>
                <Grid item sm={8} xs={8} container justifyContent="flex-start">
                  VENTAS DIRECTAS
                </Grid>
                <Grid item sm={2} xs={2}>
                  <ArrowForwardIcon />
                </Grid>
              </Grid>
            </Button>
          </Grid>
        </Grid>

        <Grid item container spacing={3}>
          <Grid item container sm={12} xs={12} alignItems="center">
            <Button className={classes.shortcutButton} onClick={redirectFinishedAuctions}>
              <Grid item container alignItems="center">
                <Grid item sm={2} xs={2} container justifyContent="flex-start">
                  <GavelIcon className={classes.areaImage} style={{ width: '2.6vh' }} />
                </Grid>
                <Grid item sm={8} xs={8} container justifyContent="flex-start">
                  REMATES FINALIZADOS
                </Grid>
                <Grid item sm={2} xs={2}>
                  <ArrowForwardIcon />
                </Grid>
              </Grid>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      {propertyGrid}
      {furnitureGrid}
    </>
  );
}

export default MobileLayout;
