import React, { useCallback } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import GavelIcon from '@mui/icons-material/Gavel';
import buildingImg from 'utils/images/building.png';
import keyImg from 'utils/images/key.png';
import treeImg from 'utils/images/tree.png';
import carImg from 'utils/images/car.png';
import machineImg from 'utils/images/machine.png';
import excavator from 'utils/images/excavator.png';
import { useHistory } from 'react-router-dom';
import { basicUrlPagination, getAuctionTypeName, getItemInStorage } from 'utils/functions';
import { defaultQueries, externalLink } from 'utils/helpers/helper';
import SvgArrow from './SVGArrow';

function DesktopLayout(props) {
  const { classes, propertyLive, furnitureLive, globals, user } = props;
  const history = useHistory();
  const propertyLiveCode = propertyLive?.AuctionType?.code;
  const propertyName = getAuctionTypeName(propertyLive?.AuctionType?.code, 2).live;
  const furtinureName = getAuctionTypeName(furnitureLive?.AuctionType?.code, 1).live;

  // const gotoExternal = useCallback(() => {
  //   window.open(globals.externalLogin, '_blank', 'noopener');
  // }, []);

  const redirectSale = () => {
    if (user === undefined || user.roleId === null || Object.keys(user).length === 0) {
      history.push('/direct_sale');
    } else {
      history.push(`/lots?areaId=1&lotType=sale${basicUrlPagination}`);
    }
  };

  const handleClickRedirection = (path, e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(path);
  };
  const link = globals.rolExternalLink;

  const externalROL = (evt, areaID) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (user) {
      return window.open(`${externalLink(getItemInStorage('user'), link)}&param2=${areaID}`);
    }

    return history.push('/login');
  };

  const propertyGrid = (
    <Grid item xs={6} className={classes.detailsHeader}>
      <Grid container alignContent="space-between" style={{ height: '100%' }} spacing={3}>
        <Grid container>
          <Grid item xs={8}>
            <Typography className={classes.detailsTitle}>PROPIEDADES</Typography>
          </Grid>
          <Grid item xs={4} className={classes.alignRight}>
            {propertyLive && propertyLiveCode !== 'BID' && (
              <Button
                component="a"
                href={`${externalLink(getItemInStorage('user'), link)}&param2=2`}
                className={classes.onLiveButton}
                onClick={(evt) => externalROL(evt, 2)}>
                <span className={classes.liveText}>Ir {propertyName}</span>
                <GavelIcon
                  style={{ marginLeft: '6px' }}
                  fontSize="large"
                  className={classes.gavelIcon}
                />
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Button
              fullWidth
              className={classes.shortcutButton}
              onClick={(e) =>
                handleClickRedirection(`/lots?areaId=2&auctionCategoryId=5${basicUrlPagination}`, e)
              }
              component="a"
              href={`/lots?areaId=2&auctionCategoryId=5${basicUrlPagination}`}
              startIcon={
                <img src={buildingImg} className={classes.areaImage} alt="Urban properties icon" />
              }
              endIcon={<SvgArrow className={classes.areaImage} />}>
              <span className={classes.areaText}>URBANAS</span>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              className={classes.shortcutButton}
              onClick={(e) =>
                handleClickRedirection(`/lots?areaId=2&auctionCategoryId=4${basicUrlPagination}`, e)
              }
              component="a"
              href={`/lots?areaId=2&auctionCategoryId=4${basicUrlPagination}`}
              startIcon={<img src={treeImg} className={classes.areaImage} alt="Fields icon" />}
              endIcon={<SvgArrow className={classes.areaImage} />}>
              <span className={classes.areaText}>CAMPOS</span>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              className={classes.shortcutButton}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                window.location.href = 'https://arriendos.tattersallap.cl';
              }}
              component="a"
              href="https://arriendos.tattersallap.cl"
              startIcon={<img src={keyImg} className={classes.areaImage} alt="Rents icon" />}
              endIcon={<SvgArrow className={classes.areaImage} />}>
              <span className={classes.areaText}>ARRIENDOS</span>
            </Button>
          </Grid>
        </Grid>
        <Grid item container spacing={3}>
          <Grid item xs={12} md={12} lg={6}>
            <Button
              fullWidth
              className={classes.bigLightBlueButton}
              onClick={(e) =>
                handleClickRedirection(`/auctions${defaultQueries.properties.auctions}`, e)
              }
              component="a"
              href={`/auctions${defaultQueries.properties.auctions}`}>
              <span style={{ textAlign: 'center' }}>PRÓXIMOS REMATES Y LICITACIONES</span>
            </Button>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <Button
              fullWidth
              className={classes.bigLightBlueButton}
              onClick={(e) =>
                handleClickRedirection(`/lots?areaId=2&lotType=sale${basicUrlPagination}`, e)
              }
              component="a"
              href={`/lots?areaId=2&lotType=sale${basicUrlPagination}`}>
              VENTAS DIRECTAS
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const furnitureGrid = (
    <Grid item xs={6} className={classes.detailsHeader}>
      <Grid container alignContent="space-between" style={{ height: '100%' }} spacing={3}>
        <Grid container>
          <Grid item xs={8}>
            <Typography className={classes.detailsTitle}>BIENES</Typography>
          </Grid>
          <Grid item xs={4} className={classes.alignRight}>
            {furnitureLive && (
              <Button
                component="a"
                href={`${externalLink(getItemInStorage('user'), link)}&param2=1`}
                className={classes.onLiveButton}
                onClick={(evt) => externalROL(evt, 1)}>
                <span className={classes.liveText}> Ir {furtinureName} </span>
                <GavelIcon
                  style={{ marginLeft: '6px' }}
                  fontSize="large"
                  className={classes.gavelIcon}
                />
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Button
              fullWidth
              className={classes.shortcutButton}
              onClick={(e) =>
                handleClickRedirection(`/lots?areaId=1&auctionCategoryId=2${basicUrlPagination}`, e)
              }
              startIcon={<img src={excavator} className={classes.areaImage} alt="Machines icon" />}
              endIcon={<SvgArrow className={classes.areaImage} />}
              component="a"
              href={`/lots?areaId=1&auctionCategoryId=2${basicUrlPagination}`}>
              <span className={classes.areaText}>MAQUINARIAS</span>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              className={classes.shortcutButton}
              onClick={(e) =>
                handleClickRedirection(`/lots?areaId=1&auctionCategoryId=3${basicUrlPagination}`, e)
              }
              startIcon={
                <img src={machineImg} className={classes.areaImage} alt="Equipments icon" />
              }
              endIcon={<SvgArrow className={classes.areaImage} />}
              component="a"
              href={`/lots?areaId=1&auctionCategoryId=3${basicUrlPagination}`}>
              <span className={classes.areaText}>EQUIPOS</span>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              className={classes.shortcutButton}
              onClick={(e) =>
                handleClickRedirection(`/lots?areaId=1&auctionCategoryId=1${basicUrlPagination}`, e)
              }
              startIcon={<img src={carImg} className={classes.areaImage} alt="Vehicles icon" />}
              endIcon={<SvgArrow className={classes.areaImage} />}
              component="a"
              href={`/lots?areaId=1&auctionCategoryId=1${basicUrlPagination}`}>
              <span className={classes.areaText}>VEHICULOS</span>
            </Button>
          </Grid>
        </Grid>
        <Grid item container spacing={3}>
          <Grid item xs={12} md={12} lg={6}>
            <Button
              fullWidth
              className={classes.bigButton}
              onClick={(e) =>
                handleClickRedirection(`/auctions${defaultQueries.fortinures.auctions}`, e)
              }
              component="a"
              href={`/auctions${defaultQueries.fortinures.auctions}`}>
              <span style={{ textAlign: 'center' }}>PRÓXIMOS REMATES / LICITACIONES</span>
            </Button>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <Button
              fullWidth
              className={classes.bigButton}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                redirectSale();
              }}
              component="a"
              href={
                user === undefined || user.roleId === null || Object.keys(user).length === 0
                  ? '/direct_sale'
                  : `/lots?areaId=1&lotType=sale${basicUrlPagination}`
              }>
              VENTAS DIRECTAS
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      {propertyGrid}
      {furnitureGrid}
    </>
  );
}

export default DesktopLayout;
