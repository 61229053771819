const Paths = {
  PROFILE: '/profile',
  WEBPAY_FINAL: '/webpay/final',
  AUCTION_CATEGORIES: '/auction_categories',
  AUCTIONS: '/auctions',
  PAYMENTS: '/payments',
  NEW_PAYMENT: '/payments/new',
  GUARANTEES: '/guarantees',
  GUARANTEES_AND_PAYMENTS: '/guarantees_payments_manager',
  AUCTIONS_MANAGER: '/auctions_manager/:auctionId?',
  GUARANTEE_MANAGER: '/guarantee_manager/:lotId?',
  GUARANTEE_MANAGER_ROOT: '/guarantee_manager',
  GUARANTEE_MANAGERV2: '/guarantee_managerv2/:lotId?',
  GUARANTEE_MANAGERV2_ROOT: '/guarantee_managerv2',
  GUARANTEE_PROP_STEP1: '/guarantee/prop/step1',
  GUARANTEE_PROP_STEP2: '/guarantee/prop/step2',
  GUARANTEE_PROP_STEP3: '/guarantee/prop/step3',
  GUARANTEE_PROP_STEP4: '/guarantee/prop/step4',
  FINISH_PAYMENT: '/guarantee/prop/finishPayment',
  SORT_LOTS: '/sort_lots',
  ENTITLED_LOTS: '/entitled_lots',
  // INTEGRATION_MANAGER: '/integration_manager',
  LOT_CATEGORIES: '/lot_categories',
  LOT_DETAILS: '/lots/:lotId',
  CONTACT_MESSAGES: '/contact_messages',
  LOGIN: '/login',
  FIND_USER: '/find_user',
  REGISTER_USER: '/register_user',
  ACTIVATE: '/activate',
  RESET_PASSWORD: '/reset_password',
  REGISTER: '/sign_up',
  LOTS_MANAGER: '/lots_manager/:id',
  USERS: '/users',
  EDIT_USER: '/users/edit/:id',
  LOTS: '/lots',
  SELLER_MANAGER: '/seller_manager',
  VIEW_REPORT: '/view_report',
  FEATURED_LOTS_MANAGER: '/featured_lots',
  CONTACTS_MANAGER: '/direct_sale_contacts_manager',
  BRANDS_MANAGER: '/brands_manager',
  MODELS_MANAGER: '/brands_models_manager',
  VEHICLE_TYPES_MANAGER: '/vehicle_types_manager',
  INSURANCE_INSTITUTIONS_MANAGER: '/insurance_institutions_manager',
  DIRECT_SALE: '/direct_sale/:type?',
  DIRECT_SALE_LOTS: '/direct_sale/:type/lots',
  TRANSFERS_LOGIN: '/transfers/login',
  TRANSFERS_LOTS: '/transfers/lots',
  TRANSFERS_LOTS_DETAILS: '/transfers/lots/:transferId',
  TRANSFERS_RECORD_CERTIFICATE: '/transfers/record_certificate/:transferId',
  TRANSFERS_WORKSHEET: '/transfers/worksheet/:transferId',
  TRANSFERS_MANDATE_PREVIEW: '/transfers/mandate_preview/:transferId',
  TRANSFERS_FORM_TWENTY_THREE: '/transfers/form_23/:transferId',
  TRANSFERS_PENDING: '/transfers/pending',
  MANDATES_LOGIN: '/mandates/login',
  MANDATES_HOME: '/mandates',
  MANDATES_LOTS: '/mandates/lots',
  MANDATES_MANAGER: '/mandates/manager',
  MANDATES_BULK_LOAD: '/mandates/bulk_load',
  CURRENCY_MANAGER: '/currency_manager',
  FAQ: '/faq',
  ADJUDICATIONS: '/adjudications',
  SYSTEM_CHANGES: '/system_changes',
  FINISHED_AUCTIONS: '/:areaID/finished_auctions',
  POSTURE_MANAGER: '/posture_manager',
  HOME: '/',
  PAGE_NOT_FOUND: '*',
};

export default Paths;
