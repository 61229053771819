import { put, spawn, takeLatest } from 'redux-saga/effects';

import { get, post, postForm } from 'utils/api/api';
import controlledCall from 'utils/services/controlledSaga';
import { appActions } from 'commons/reducer/commons.reducer';
import { history } from 'utils/history';
import { unformatParse } from 'utils/functions';
import { NEW_PAYMENT, newPaymentActions } from '../reducer/newPayment.reducer';

function* initialQuery() {
  yield takeLatest(NEW_PAYMENT.INITIAL_QUERY, function* (action) {
    yield controlledCall(
      get,
      `api/v1/payments?${new URLSearchParams({ guaranteeId: action.controls.guaranteeId })}`,
      undefined,
      newPaymentActions.initialQuerySuccess,
      newPaymentActions.initialQueryFail,
    );
  });
}

function* getStateFromApi() {
  yield takeLatest(NEW_PAYMENT.GET_STATE_FROM_API, function* (action) {
    const successFunction = (payload) => ({
      type: NEW_PAYMENT.GET_STATE_FROM_API_SUCCESS,
      payload,
    });

    const failFunction = () => ({ type: NEW_PAYMENT.GET_STATE_FROM_API_FAIL });

    yield controlledCall(
      get,
      `api/v1/guarantees/${action.controls.guaranteeId}/to_payment`,
      undefined,
      successFunction,
      failFunction,
    );
  });
}

function* doPaymentProp() {
  yield takeLatest(NEW_PAYMENT.DO_PAYMENT, function* (action) {
    yield put(appActions.setLoader('doPaymentProp', true));
    const { amount, paymentMode } = action.controls;
    if (paymentMode === 'webpay') {
      const body = {
        guaranteeId: action.guaranteeId,
        baseUrl: window.location.origin,
        returnUrl: '/webpay/return',
        finalUrl: '/webpay/final',
        guaranteeUrl: '/guarantees',
        auctionUrl: '/auctions',
        retryUrl: '/payments/new?retry=true',
        amount: unformatParse(amount),
      };
      yield controlledCall(
        post,
        'api/v1/payments/easy-pay',
        body,
        newPaymentActions.doPaymentSuccess,
      );
    } else {
      const data = new FormData();
      const amounts = [];
      action.controls.receipts.forEach((receipt) => {
        data.append(`receipts|${receipt.index}|`, receipt.file, receipt.file.name);
        amounts.push({
          index: receipt.index,
          amount: receipt.amount,
        });
      });
      data.append('guaranteeId', action.guaranteeId);
      data.append('amounts', JSON.stringify(amounts));
      const result = yield controlledCall(
        postForm,
        'api/v1/payments/receipt',
        data,
        newPaymentActions.doPaymentSuccess,
      );

      if (!result?.error) {
        yield put(appActions.setSuccess('Se han subido los comprobantes de pago'));
        if (!action.paid) {
          result.paymentData.isProperty = action.isProperty;
          yield put(appActions.setPaymentModal(result.paymentData));
        }
        if (result.showAuctionMessage) {
          if (action.isProperty && action.isBidding && action.biddingEmail) {
            yield put(
              appActions.setEmailReminder(
                `Recordar enviar oferta a notaria : ${action.biddingEmail} `,
              ),
            );
          } else {
            yield put(
              appActions.setGotoAuction(
                'Podrá ingresar al remate una vez se apruebe su recibo mediante el siguiente enlace.',
              ),
            );
          }
        }
        history.push(
          action.paid ? '/guarantee/prop/finishPayment?success=true' : '/payments',
          action.paid ? { paid: true } : null,
        );
      }
    }
    yield put(appActions.setLoader('doPaymentProp', false));
  });
}

export default function* newPaymentSagaV2() {
  yield spawn(getStateFromApi);
  yield spawn(initialQuery);
  yield spawn(doPaymentProp);
}
